import * as React from 'react'
import { useSiteMetadata } from '../../components/site/metadata'
import { CustomerPageTemplate } from './customer-page-template'

const CustomerPage = ({ pageContext }) => {
  const { site } = useSiteMetadata()
  const customer = pageContext.customer
  const page = {
    title: customer.title,
    subtitle: customer.subtitle,
    name: customer.name,
    logo: customer.logo,
    logoAlt: customer.logoAlt,
    open: customer.openMdx,
    takeaway: customer.takeawayMdx,
    challenge: customer.challengeMdx,
    challengeImage: customer.challengeImage,
    challengeImageAlt: customer.challengeImageAlt,
    result: customer.resultMdx,
    close: customer.closeMdx,
    downloadThumbnail: customer.downloadThumbnail,
    downloadThumbnailAlt: customer.downloadThumbnailAlt,
    downloadTo: `${site.url}${customer.downloadFile.replace(
      /^(\/static\/)/,
      '/'
    )}`,
    nextLinkTitle: customer.nextLinkTitle,
    nextLinkUrl: customer.nextLinkUrl,
  }

  return (
    <CustomerPageTemplate
      page={page}
      name={pageContext.name}
      pathname={pageContext.pathname}
    />
  )
}

export default CustomerPage
