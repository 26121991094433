import * as React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import {
  Container,
  DivBG,
  Link,
  LinkBox,
  Section,
  Seo,
} from '@components'
import {MDXRenderer} from "../../components/core/mdx";
import { CustomerBanner } from "../../components/site/customers"
import { PageLayout } from '../../components/site/layout/page-layout'

const CustomerPageTemplate = ({ page, pathname }) => {
  return (
    <PageLayout pathname={pathname}>
      <CustomerBanner title={page.title} subtitle={page.subtitle} alt={'grey speech bubble icon with green rectangle overlay'}/>

      <Section>
        <Container>
          <div className="flex flex-col items-center pt-4 md:flex-row">
            <div className="order-last w-64 py-4 pr-0 md:order-first md:py-0 md:pr-8">
              <GatsbyImage image={getImage(page.logo)} alt={page.logoAlt} />
            </div>
            <MDXRenderer className="flex-1 order-1 md:order-2">
              {page.open}
            </MDXRenderer>
          </div>
        </Container>

        <Container>
          <MDXRenderer className="px-4 md:px-8">{page.takeaway}</MDXRenderer>
        </Container>
      </Section>

      <Section>
        <Container className="py-2 md:py-4">
          <DivBG
            bgImage={
              <GatsbyImage
                objectFit="cover"
                objectPosition="right center"
                style={{
                  width: '100%',
                  height: '100%',
                }}
                image={getImage(page.challengeImage)}
                alt={page.challengeImageAlt}
                placeholder="blurred"
              />
            }
          >
            <div className="flex w-full bg-white bg-opacity-80 lg:bg-opacity-0 lg:bg-gradient-to-r lg:from-white/80 lg:via-white/80">
              <MDXRenderer className="w-full px-2 py-2 lg:w-1/2 md:px-16">
                {page.challenge}
              </MDXRenderer>
            </div>
          </DivBG>
        </Container>
      </Section>

      <Section className="">
        <Container className="">
          <MDXRenderer>{page.result}</MDXRenderer>
        </Container>

        <Container className="pt-2 pb-14 md:pt-0">
          <div className="flex flex-col items-center px-8 md:flex-row">
            <MDXRenderer className="flex-1 md:pr-8">{page.close}</MDXRenderer>
            <div className="flex items-center justify-center w-64">
              <p>Read the Full Story:</p>
              <LinkBox
                className="flex items-center justify-center w-32 h-auto"
                href={page.downloadTo}
                blank
              >
                <GatsbyImage
                  style={{ w: '100%', h: '100%', objectFit: 'cover' }}
                  image={getImage(page.downloadThumbnail)}
                  alt={page.downloadThumbnailAlt}
                />
              </LinkBox>
            </div>
          </div>
        </Container>
      </Section>
      <Section style={{background: '#ddd'}}>
        <Container>
          <div style={{ textAlign: "center", paddingBottom: "50px", paddingTop: "50px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Link style={{ fontSize: '16px', fontWeight: 'bold'}} href={page.nextLinkUrl}>{page.nextLinkTitle} <span className="text-2xl align-text-bottom" style={{ lineHeight: '0.87', paddingLeft: 5 }}> »  </span> </Link>
          </div>
        </Container>
      </Section>
    </PageLayout>
  )
}

export { CustomerPageTemplate }
